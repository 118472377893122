const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    children: [{ path: "", component: () => import("../pages/IndexPage.vue") }],
  },
  {
    path: "/auth",
    component: () => import("../layouts/AuthLayout.vue"),
    children: [
      { path: "", component: () => import("../pages/auth/LoginUser.vue") },
      {
        path: "register",
        component: () => import("../pages/auth/RegisterUser.vue"),
      },
    ],
  },
  {
    path: "/account",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      { path: "", component: () => import("../pages/account/DashBoard.vue") },
      {
        path: "profile",
        component: () => import("../pages/account/UpdateProfile.vue"),
      },
    ],
  },
  {
    path: "/projects",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("../pages/projects/ProjectList.vue"),
      },
      {
        path: "view",
        component: () => import("../pages/projects/ProjectView.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/projects/ProjectEdit.vue"),
      },
    ],
  },
  {
    path: "/products",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("../pages/products/ProductList.vue"),
      },
      {
        path: "view",
        component: () => import("../pages/products/ProductView.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/products/ProductEdit.vue"),
      },
    ],
  },
  {
    path: "/contacts",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("../pages/contacts/ContactList.vue"),
      },
      {
        path: "view",
        component: () => import("../pages/contacts/ContactView.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/contacts/ContactEdit.vue"),
      },
    ],
  },
  {
    path: "/quotes",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("../pages/quotes/QuoteList.vue"),
      },
      {
        path: "view",
        component: () => import("../pages/quotes/QuoteView.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/quotes/QuoteEdit.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/ErrorNotFound.vue"),
  },
];

export default routes;
