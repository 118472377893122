// This is just an example,
// so you can safely delete all default props below

export default {
  failed: "Action failed",
  success: "Action was successful",
  createAccountHeading: "Create your TintEdge account",
  createAccountText: "Please enter your details below",
  firstNameLabel: "Your First Name",
  firstNameError: "Please enter your first name",
  lastNameLabel: "Your Last Name",
  lastNameError: "Please enter your last name",
  emailLabel: "Email Address",
  emailError: "Please enter your email address",
  passwordLabel: "Password",
  passwordError: "Please enter a password",
  acceptTermsLabel: "I accept the terms & conditions",
  acceptTermsError:
    "You must accept the terms and conditions to register for TintEdge.",
  registerLabel: "Register",
  alreadyRegistered: "Already registered?",
  loginHere: "Login Here",
  registrationError: "RegistrationError",
  ok: "OK",
  contactTableTitle: "Contacts",
  productTableTitle: "Products",
  projectTableTitle: "Projects",
  quoteTableTitle: "Quotes",
};
