import { createApp } from 'vue'
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import { router } from './routes';
import { createI18n } from 'vue-i18n';
import messages from "./i18n";

import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const i18n = createI18n({
    legacy: false,
    locale: "en-US",
    fallbackLocale: 'en-US',
    globalInjection: true,
    messages,
  });

  // Set i18n instance on app
app.use(i18n);
app.use(router);
app.use(ElementPlus)
app.use(pinia);
app.mount('#app')